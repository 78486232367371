import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import HubspotForm from 'react-hubspot-form'

export default function GetADemoSalesforce() {
  return (
    <Layout
      title="Book a IrisAgent product demo for Salesforce"
      keywords="IrisAgent, demo, Salesforce, incidents, tickets"
      description="Book a demo to see how IrisAgent works with Salesforce. Uplevel your customer support operations, reduce ticket resolution times and increase customer happiness with IrisAgent."
    >
      {/* <!-- ======= Breadcrumbs ======= -->
    <!-- <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Book Demo</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li>Demo</li>
          </ol>
        </div>

      </div>
    </section> --><!-- End Breadcrumbs --> */}
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link rel="canonical" href="https://irisagent.com/get-a-demo-salesforce/" />
      </Helmet>

      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Schedule a demo today</h1>
                <br />
                <p>
                  Discover how IrisAgent can improve your customer support
                  operations natively on Salesforce Service Cloud, reduce ticket resolution times and increase
                  customer happiness by bringing a 360-degree view of customer issues
                  to your support teams and improving collaboration across your
                  organization.
                </p>
                <img
                  src="/img/hero-img.png"
                  className="img-fluid"
                  alt="Schedule-demo"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                />
                {/* <!-- <iframe src="https://share.hsforms.com/1mxZBThRQTJarmTfIVjUuvg4gmls" width="640" height="580" frameBorder="0" marginHeight={0} marginWidth={0}></iframe> --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
